/** @jsx jsx */

import MySEO from '../components/seo'
import {Grid,jsx,Box,Container,Flex,Heading,Text} from 'theme-ui'
import Layout from '../components/layout'

export default function Satzung(){
    return(
     <Layout>
         <Flex>
<Container sx={{m:0}}>


<MySEO title="Unsere Satzung" description="Die Satzungs des Kulturnetzes Oberes Mittelrheintal" />

<Box sx={{m:0,p:0}}><Heading sx={{color:'text',mt:30,fontWeight:500}} as="h1">Die Satzung</Heading></Box>
 


<Grid 
columns={[1,2,2,2, `1fr`,`1fr`]}
 gap={3}
>

<Box sx={{mr:2,pb:60}}>

<Heading sx={{color:'text',mt:30}}> § 1 (Name und Sitz)  

</Heading> 
<Text>

Kulturnetz Oberes Mittelrheintal  <br/>  
Er soll in das Vereinsregister eingetragen werden und trägt dann den Zusatz "e.V." <br/>
Der Sitz des Vereins ist Bacharach.   
</Text>
<Heading sx={{color:'text',mt:30}}> § 2 (Geschäftsjahr)   </Heading> 
<Text>Geschäftsjahr ist das Kalenderjahr. </Text>  
<Heading sx={{color:'text',mt:30}}> § 3 (Zweck des Vereins) </Heading>   
<Text>
Der Verein verfolgt ausschließlich und unmittelbar gemeinnützige Zwecke im Sinne des Abschnitts "Steuerbegünstigte Zwecke" der Abgabenordnung.<br/>
Der Satzungszweck wird verwirklicht insbesondere durch
</Text>  

<br/> 

<Text>
1. Förderung von Kunst und Kultur durch finanzielle und ideelle Unterstützung mit dem Schwerpunkt Welterbe Oberes Mittelrheintal<br/>
2. die Förderung und Durchführung von analogen und digitalen kulturellen und wissenschaftlichen Veranstaltungen, Kunstausstellungen, Konzerten, Theatervorstellungen, Vorträgen und Organisation von Märkten (Kunsthandwerk) sowie Durchführung von Bildungsveranstaltungen<br/>
3. die Koordinierung geeigneter Veranstaltungen mit anderen kulturellen Einrichtungen<br/>
4. Der Verein strebt neben dem Kulturraum Markt 1 in Bacharach weitere Veranstaltungs- und Ausstellungsräume an. 
</Text>
 
  


<Heading sx={{color:'text',mt:30}}> § 4 (Selbstlose Tätigkeit)  </Heading>  
<Text>
Der Verein ist selbstlos tätig; er verfolgt nicht in erster Linie eigenwirtschaftliche Zwecke. 
</Text>  
<Heading sx={{color:'text',mt:30}}> § 5 (Mittelverwendung)  </Heading>  
<Text>
Mittel des Vereins dürfen nur für die satzungsmäßigen Zwecke verwendet werden. Die Mitglieder erhalten keine Zuwendungen aus Mitteln des Vereins.   

</Text>
<Heading sx={{color:'text',mt:30}}> § 6 (Verbot von Begünstigungen)  </Heading>  
<Text>
Es darf keine Person durch Ausgaben, die dem Zweck des Vereins fremd sind, oder durch unverhältnismäßig hohe Vergütungen begünstigt werden. 
</Text>  
<Heading sx={{color:'text',mt:30}}> § 7 (Erwerb der Mitgliedschaft)   </Heading> 
<Text>
Vereinsmitglieder können natürliche oder juristische Personen werden.<br/>
Der Aufnahmeantrag ist schriftlich zu stellen.<br/>
Über den Aufnahmeantrag entscheidet der Vorstand.<br/>
Gegen die Ablehnung, die keiner Begründung bedarf, steht dem/der Bewerber/in die Berufung an 
die Mitgliederversammlung zu, welche dann endgültig entscheidet. 
</Text>  

<Heading sx={{color:'text',mt:30}}> § 8 (Beendigung der Mitgliedschaft) </Heading>   
<Text>
Die Mitgliedschaft endet durch Austritt, Ausschluss, Tod oder Auflösung der juristischen Person.   
Der Austritt erfolgt durch schriftliche Erklärung gegenüber einem vertretungsberechtigten Vorstandsmitglied. 
Die schriftliche Austrittserklärung muss mit einer Frist von einem Monat jeweils zum Ende des Geschäftsjahres gegenüber dem Vorstand erklärt werden.
</Text>
<Text>
Ein Ausschluss kann nur aus wichtigem Grund erfolgen. Wichtige Gründe sind insbesondere ein die Vereinsziele schädigendes Verhalten, die Verletzung satzungsmäßiger Pflichten oder Beitragsrückstände von mindestens einem Jahr. Über den Ausschluss entscheidet der Vorstand. Gegen den Ausschluss steht dem Mitglied die Berufung an die Mitgliederversammlung zu, die schriftlich binnen eines Monats an den Vorstand zu richten ist. Die Mitgliederversammlung entscheidet im Rahmen des Vereins endgültig. Dem Mitglied bleibt die Überprüfung der Maßnahme durch Anrufung der ordentlichen Gerichte vorbehalten. Die Anrufung eines ordentlichen Gerichts hat aufschiebende Wirkung bis zur Rechtskraft der gerichtlichen Entscheidung.
</Text>


 


<Heading sx={{color:'text',mt:30}}> § 9 (Beiträge)   </Heading> 
<Text>
Von den Mitgliedern werden Beiträge erhoben. <br/>
Die Höhe der Beiträge und deren Fälligkeit bestimmt die Mitgliederversammlung.<br/>
</Text>


<Heading sx={{color:'text',mt:30}}> § 10 (Organe des Vereins)</Heading>    
<Text>Organe des Vereins sind die Mitgliederversammlung und der Vorstand.  </Text> 


 </Box>
<Box>
 

<Heading sx={{color:'text',mt:30}}> § 11 (Mitgliederversammlung) </Heading>   
<Text>
Die Mitgliederversammlung ist das oberste Vereinsorgan.   
Zu ihren Aufgaben gehören insbesondere 
die Wahl und Abwahl des Vorstands, Entlastung des Vorstands, 
Entgegennahme der Berichte des Vorstandes, 
Wahl der Kassenprüfern/innen Festsetzung von Beiträgen und deren Fälligkeit, Beschlussfassung über 
die Änderung der Satzung, Beschlussfassung über die Auflösung des Vereins, Entscheidung über Aufnahme 
und Ausschluss von Mitgliedern in Berufungsfällen sowie weitere Aufgaben, 
soweit sich diese aus der Satzung oder nach dem Gesetz ergeben.<br/>
Im jedem Geschäftsjahr findet eine ordentliche Mitgliederversammlung statt, in besonderen Fällen online.   

</Text>
   <Text>
   Der Vorstand ist zur Einberufung einer außerordentlichen Mitgliederversammlung verpflichtet,
wenn mindestens ein Drittel der Mitglieder dies schriftlich unter Angabe von Gründen verlangt.
   </Text>

   
 <Text>Die Mitgliederversammlung wird vom Vorstand unter Einhaltung einer Frist von einem Monat
schriftlich oder digital unter Angabe der Tagesordnung einberufen. 
Die Frist beginnt mit dem auf die Absendung des Einladungsschreibens folgenden Tag. 
Das Einladungsschreiben gilt als den Mitgliedern zugegangen, 
wenn es an die letzte dem Verein bekannt gegebene Anschrift gerichtet war.</Text>  

<Text>
   
Die Tagesordnung ist zu ergänzen, wenn dies ein Mitglied bis spätestens eine Woche vor dem angesetzten Termin schriftlich beantragt. Die Ergänzung ist zu Beginn der Versammlung bekanntzumachen.
</Text>
<Text>
Anträge über die Abwahl des Vorstands, über die Änderung der Satzung und über die Auflösung des Vereins, die den Mitgliedern nicht bereits mit der Einladung zur Mitgliederversammlung zugegangen sind, können erst auf der nächsten Mitgliederversammlung beschlossen werden.
</Text>
   
<Text>
Die Mitgliederversammlung ist ohne Rücksicht auf die Zahl der erschienenen Mitglieder beschlussfähig.   
Die Mitgliederversammlung wird von einem Vorstandsmitglied geleitet.
</Text>
   

 <Text>
 Zu Beginn der Mitgliederversammlung ist ein Schriftführer zu wählen.
 </Text>  

<Text>
       
Jedes Mitglied hat eine Stimme. Das Stimmrecht kann nur persönlich oder für ein Mitglied unter Vorlage einer schriftlichen Vollmacht ausgeübt werden.
</Text>
   
<Text>
Bei Abstimmungen entscheidet die einfache Mehrheit der abgegebenen Stimmen.
</Text>

<Text>
Satzungsänderungen und die Auflösung des Vereins können nur mit einer Mehrheit von 2/3 der anwesenden Mitglieder beschlossen werden.
</Text>   

<Text>
Stimmenthaltungen und ungültige Stimmen bleiben außer Betracht.
</Text>

   
<Text>Über die Beschlüsse der Mitgliederversammlung ist ein Protokoll anzufertigen, das vom Versammlungsleiter und dem Schriftführer zu unterzeichnen ist.</Text>

 
<Heading sx={{color:'text',mt:30}}> § 12 (Vorstand)   </Heading> 
<Text>
Der Vorstand besteht aus bis zu sieben Personen, dem/der 1. und 2. Vorsitzenden, dem/der Kassierer/in und bis zu vier Beisitzer/innen (Gesamtvorstand).   <br/>
Der Vorstand im Sinn des § 26 BGB besteht aus dem/der 1. und 2. Vorsitzenden und dem/der Kassierer/in (Vertretungsvorstand). Sie vertreten den Verein gerichtlich und außergerichtlich. Zwei Vorstandsmitglieder/innen des Vertretungsvorstands vertreten gemeinsam.   
Der Vorstand wird von der Mitgliederversammlung auf die Dauer von zwei Jahren gewählt.
</Text>
   <Text>
   Vorstandsmitglieder können nur Mitglieder des Vereins werden. 
   </Text>
<Text>
Wiederwahl ist zulässig.
</Text>
 <Text>
 Der Vorstand bleibt solange im Amt, bis ein neuer Vorstand gewählt ist.
</Text>  
<Text>
Bei Beendigung der Mitgliedschaft im Verein endet auch das Amt als Vorstand.
</Text>
 
<Heading sx={{color:'text',mt:30}}> § 13 (Kassenprüfung) </Heading> 
<Text>
Die Mitgliederversammlung wählt für die Dauer von zwei Jahren zwei Kassenprüfer/innen.
   
</Text>  
<Text>
Diese dürfen nicht Mitglied des Vorstands sein.   <br/>
Wiederwahl ist zulässig.
</Text>

   
<Heading sx={{color:'text',mt:30}}> § 14 (Auflösung des Vereins)  </Heading>  
<Text>
Bei Auflösung des Vereins fällt dessen Vermögen an Zweckverband Welterbe Oberes Mittelrheintal, 
der es unmittelbar und ausschließlich für gemeinnützige Zwecke zu verwenden hat.<br/>   
Bei Wegfall der steuerbegünstigenden Zwecke hat der Verein das Vermögen unmittelbar und 
ausschließlich für die Zwecke gemäß § 3 dieser Satzung zu verwenden.     
Bacharach, 29.08.2020

</Text>   

</Box>
</Grid>
</Container>
</Flex>
     </Layout>
    )
}
